/* Core CSS required for Ionic components to work properly */
@import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/react/css/normalize.css';
@import '@ionic/react/css/structure.css';
@import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/react/css/padding.css';
@import '@ionic/react/css/float-elements.css';
@import '@ionic/react/css/text-alignment.css';
@import '@ionic/react/css/text-transformation.css';
/*@import '@ionic/react/css/flex-utils.css';*/
@import '@ionic/react/css/display.css';

@import './variables.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-base font-sans text-diercke-blue;
  }

  *,
  ::after,
  ::before {
    @apply border-diercke-blue/25;
  }

  details summary::marker,
  details summary::-webkit-details-marker {
    display: none;
  }

  summary {
    list-style: none;
  }
}

@layer components {
  @import './components/icon.css';
  @import './components/dropdown-tree-select.css';
}

@layer utilities {
  .border-inset {
    border-style: inset;
  }

  .hyphens-auto {
    hyphens: auto;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .tint-black-blue {
    filter: invert(16%) sepia(80%) saturate(1716%) hue-rotate(188deg) brightness(93%) contrast(102%);
  }
}

/* lato-300 - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('./fonts/lato-v23-latin-ext_latin-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/lato-v23-latin-ext_latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-regular - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('./fonts/lato-v23-latin-ext_latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/lato-v23-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-700 - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('./fonts/lato-v23-latin-ext_latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/lato-v23-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-900 - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local(''), url('./fonts/lato-v23-latin-ext_latin-900.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/lato-v23-latin-ext_latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.ourDesign {
  color: black;
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 45px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
}

.dropdown:hover .dropdown-content {
  display: block;
}
