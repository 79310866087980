.diercke-icon {
  @apply text-diercke-blue;
}

.diercke-icon path:not([fill]),
.diercke-icon rect:not([fill]),
.diercke-icon polygon:not([fill]),
.diercke-icon circle:not([fill]) {
  @apply fill-current;
}
