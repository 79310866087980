:root {
  --ion-color-diercke-blue: #daeaf4;
  --ion-color-diercke-blue-rgb: 218, 234, 244;
  --ion-color-diercke-blue-contrast: #000000;
  --ion-color-diercke-blue-contrast-rgb: 0, 0, 0;
  --ion-color-diercke-blue-shade: #c0ced7;
  --ion-color-diercke-blue-tint: #deecf5;
}

.ion-color-diercke-blue {
  --ion-color-base: var(--ion-color-diercke-blue);
  --ion-color-base-rgb: var(--ion-color-diercke-blue-rgb);
  --ion-color-contrast: var(--ion-color-diercke-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-diercke-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-diercke-blue-shade);
  --ion-color-tint: var(--ion-color-diercke-blue-tint);
}
