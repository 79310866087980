.react-dropdown-tree-select .hide:not(.match-in-children) {
  @apply hidden;
}

.react-dropdown-tree-select .dropdown,
.react-dropdown-tree-select .dropdown-trigger,
.react-dropdown-tree-select .tag-list,
.react-dropdown-tree-select .tag-item {
  @apply flex w-full h-full;
}

.react-dropdown-tree-select .dropdown-content {
  @apply absolute top-full w-full py-2 bg-white z-10 border border-t-0 rounded-b-2xl shadow-2xl overflow-hidden;
}

.react-dropdown-tree-select .tag,
.react-dropdown-tree-select .search {
  @apply absolute inset-0 block leading-[38px] w-full h-full pb-0.5 px-[50px] border border-diercke-blue-400 bg-white rounded-xl overflow-hidden whitespace-nowrap overflow-ellipsis;
}

.react-dropdown-tree-select .tag {
  @apply z-20;
}

.react-dropdown-tree-select .dropdown-trigger[aria-expanded='true'] .tag,
.react-dropdown-tree-select .dropdown-trigger[aria-expanded='true'] .search {
  @apply rounded-b-none;
}

.react-dropdown-tree-select .tag-remove {
  @apply absolute right-0 top-0 h-full w-[50px] z-30 pointer-events-auto overflow-hidden text-transparent;
}

.react-dropdown-tree-select .infinite-scroll-component {
  @apply overflow-hidden overflow-y-auto min-h-[200px] max-h-[calc(50vh-200px)] md:max-h-[calc(100vh-210px)];
  overflow-x: hidden !important; /* override component inline style */
}

.react-dropdown-tree-select .tag-remove::after {
  @apply block absolute inset-0;
  content: url('~/src/svg/Icn/40px/Schliesskreuz-01.svg');
  /* tint black to diercke blue also see utility class .tint-black-blue */
  filter: invert(16%) sepia(80%) saturate(1716%) hue-rotate(188deg) brightness(93%) contrast(102%);
}

.react-dropdown-tree-select ul {
}

.react-dropdown-tree-select .node {
  @apply flex flex-grow pr-2 items-stretch border-t first:border-t-0 relative;
  padding-left: 40px !important; /* dropdown-tree-select adds a inline padding-left: 0px; that we need to override */
}

.react-dropdown-tree-select .node[aria-level='2'] {
  padding-left: 60px !important; /* dropdown-tree-select adds a inline padding-left: 20px; that we need to override */
}

.react-dropdown-tree-select .node label {
  @apply flex flex-grow;
}

.react-dropdown-tree-select .node-label {
  @apply flex flex-grow cursor-pointer py-2.5 leading-tight;
}

.react-dropdown-tree-select .node.focused {
  @apply bg-diercke-blue-150;
}

.react-dropdown-tree-select .node.disabled {
  @apply text-gray-600 cursor-not-allowed;
}

.react-dropdown-tree-select .node.leaf.collapsed {
  @apply hidden;
}

.react-dropdown-tree-select .node.match-in-children.hide .node-label {
  @apply opacity-50;
}

.react-dropdown-tree-select .toggle::after {
  @apply absolute left-0 top-0 h-[40px] w-[40px] inline-block text-center;
}

.react-dropdown-tree-select .toggle.collapsed::after,
.react-dropdown-tree-select .toggle.expanded::after {
  /*@apply transition-transform rotate-0; */
  content: url('~/src/svg/Icn/40px/Pfeil-R.svg');
  filter: invert(16%) sepia(80%) saturate(1716%) hue-rotate(188deg) brightness(93%) contrast(102%);
}

.react-dropdown-tree-select .toggle.expanded::after {
  @apply rotate-90;
}

.react-dropdown-tree-select .searchModeOn .toggle {
  @apply hidden;
}

.react-dropdown-tree-select .checkbox-item,
.react-dropdown-tree-select .radio-item {
  @apply sr-only;
}
